<template>
  <div>
    <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增商品大类':'修改商品大类'" :close-on-click-modal="false" @closed="cancel" width="30%">
      <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="100px" v-if="form">
        <el-form-item label="大类名称" prop="className">
          <el-input v-model.trim="form.className" style="width:100%" placeholder="请填写" :maxlength="20" />
        </el-form-item>
        <el-form-item label="商品分类" prop="goodsClassIds">
          <quick-cascader v-model="form.goodsClassIds" url="api/category/tree" filterable clearable multiple placeholder="请选择分类" expand-trigger="hover" @change="handleChangeClass" style="width:100%" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="dialog = false">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { add, edit } from "@/api/goodsClass";
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      form: {
        classId: null,
        className: null,
        goodsClassIds: null,
        categoryList: null,
      },
      rules: {
        className: [
          {
            required: "true",
            message: "商品大类名称为必填项",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    cancel() {
      this.form = null;
    },
    doSave() {
      this.loading = true;
      if (this.isAdd) {
        this.doAdd();
      } else this.doEdit();
    },
    doAdd() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          add(this.form)
            .then((res) => {
              this.dialog = false;
              this.$notify({
                title: "保存成功",
                type: "success",
                duration: 2500,
              });
              this.loading = false;
              this.$parent.init();
            })
            .catch((err) => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        }
      });
    },
    // 修改
    doEdit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          edit(this.form)
            .then((res) => {
              this.dialog = false;
              this.$notify({
                title: "修改成功",
                type: "success",
                duration: 2500,
              });
              this.loading = false;
              this.$parent.init();
            })
            .catch((err) => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        }
      });
    },
    restForm(form) {
      this.form = form || {
        classId: null,
        className: null,
        goodsClassIds: null,
        categoryList: null,
      };
      this.dialog = true;
    },
    handleChangeClass(ids, data) {
      if (data) this.form.categoryList = data;
    },
  },
};
</script>

